import React, { useEffect } from "react";
import { Link } from "gatsby";
import { getSearchParams } from "gatsby-query-params"
import Layout from "../../Components/Layout";
import peacePC from "../../assets/images/zenklai.png";
import { RaisedButton } from "../../Components/Buttons";
import _fetch from "../../utils/_fetch";

function Isbandyk() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const params = getSearchParams()

  useEffect(() => {
    localStorage.setItem("token", params.t)

    _fetch(`accountloginre/${params.t}`, "GET", null, true)


  }, [params])

  return (
    <Layout>
    <div className="Isbandyk">
      <div className="isbandykMain">
        <img src={peacePC} alt="Kompiuterio iliustracija"/>
        <h5>
        Toliau bus pateikiama <span>10 klausimų</span>. <br />
        Vieno klausimo maksimali trukmė: <span>1 minutė</span> <br />
        Grįžti atgal į klausimą galimybės nėra. <br />
        Klausimai pateikiami su atsakymų variantais, kuriuos reikia pasirinkti per kuo trumpesnį laiką. <br />
        Sėkmės!
        </h5>
        <Link to="/euegzaminas/egzaminuotojas/startas">
          <RaisedButton variant="blue">Pradėti egzaminą</RaisedButton>
        </Link>
      </div>
    </div>
    </Layout>
  );
}

export default Isbandyk;